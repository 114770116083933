import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import location from '../../../assets/images/frontend/map-pin.svg';
import mail from '../../../assets/images/frontend/mail.svg';
import phone from '../../../assets/images/frontend/phone-call.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Contact.css'
import { useEffect, useState } from "react";
import sendMsg from "../../Apis/contactApi";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

const Contact = () => {
    const [formData, setFormData] = useState({
        fullName: '', email: '', phone: '', message: ''
    })
    const [isDisable, setIsDesable] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDesable(true);
        try {
            const response = await sendMsg(formData.fullName, formData.email, formData.phone, formData.message);
            if (response.status == 1) {
                Toastify({
                    text: 'Message sent',
                    className: "info",
                    style: {
                        background: "linear-gradient(to right, #00b09b, #96c93d)",
                    }
                }).showToast();
            }

        } catch (e) {
            console.error(e)
            Toastify({
                text: 'Failed to send Message',
                className: "info",
                style: {
                    background: "linear-gradient(to right, #ff0000, #6c1919)",
                }
            }).showToast();
        } finally {
            setIsDesable(false)
        }
    }

    const handleChange = (e) => {
        const inputValue = e
        const numericValue = inputValue.replace(/\D/g, '');
        setFormData({ ...formData, phone: numericValue });
    };

    useEffect(() => {
        handleChange(formData.phone);
    }, [formData.phone]);
    return (<>
        <InnerBanner pageName='Contact' />
        <section className="contact text-center text-dark section">
            <div className="container">
                <div className="row row-cols-1 row-cols-lg-3 g-4">
                    <div className="col-md-4 col-12">
                        <div className="card border address p-5 h-100">
                            <div className="mb-4">
                                <img src={location} className="icons " />
                            </div>
                            <h6 className="mb-4">Our Address</h6>
                            <address className="mb-0 fw-semibold">3310-B/2, Pusa Wall Street, Ranjit Nagar, South
                                Patel
                                Nagar, New
                                Delhi-110008,
                                India
                            </address>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="card border email p-5 h-100">

                            <div className="mb-4">
                                <img src={mail} className="icons " />
                            </div>
                            <h6 className="mb-4">Email Address</h6>
                            <p className="fw-semibold text-center">info@pythiangames.org<br /> pythiangames22@gmail.com
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="card border number p-5 h-100">
                            <div className="mb-4">
                                <img src={phone}
                                    className="icons " />
                            </div>
                            <h6 className="mb-4">Phone Number</h6>
                            <p className="fw-semibold text-center">+91 11 45520883<br /> +91 84472 25005<br /> +91
                                81444
                                55544</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="loctaion section">
            <div className="container">
                <div className="row flex-md-row-reverse">
                    <div className="col-md-6 col-12">
                        <div className="shadow p-3 p-sm-5 p-md-6">
                            <div className="card-header  px-0 pt-0 ">
                                <h2 className="mb-3 fw-bold">Drop Us a Line</h2>
                            </div>
                            <form className="card-body px-0 pb-0 pt-4" onSubmit={(e) => handleSubmit(e)}>
                                <div className="input-floating-label form-floating mb-4">
                                    <input type="text" className="form-control bg-transparent"
                                        id="floatingName" placeholder="Password" required
                                        onChange={(e) => setFormData({ ...formData, fullName: e.target.value })} />
                                    <label htmlFor="floatingName">Full name</label>
                                    <br />
                                </div>
                                <div className="input-floating-label form-floating mb-4">
                                    <input type="email" className="form-control bg-transparent" id="floatingInput"
                                        placeholder="name@example.com" required
                                        onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                    <label htmlFor="floatingInput">Email ID</label>
                                </div>
                                <div className="input-floating-label form-floating mb-4">
                                    <input type="tel" className="form-control bg-transparent" id="floatingNumber"
                                        placeholder="Phone" required value={formData.phone}
                                        onChange={(e) => {
                                            setFormData({ ...formData, phone: e.target.value })
                                        }} />
                                    <label htmlFor="floatingNumber">Phone Number</label>
                                </div>
                                <div className="input-floating-label form-floating mb-4">
                                    <textarea className="form-control bg-transparent"
                                        placeholder="Leave a comment here"
                                        id="floatingTextarea2"
                                        style={{ height: "100px" }} required
                                        onChange={(e) => setFormData({
                                            ...formData, message: e.target.value
                                        })}></textarea>
                                    <label htmlFor="floatingTextarea2">Message</label>
                                </div>
                                <button className="action-button mt-2 mt-md-4">
                                    <span></span><i>
                                        <input type='onSubmit' value='Send Message' className='submit-btn' disabled={isDisable} /></i>
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <iframe className="w-100 h-100 grayscale d-block mt-8"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d875.3952082525467!2d77.16997157535548!3d28.642322394556224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d038bb473d13d%3A0x9a7105c1bb661dfa!2sModern%20Pythian%20Games!5e0!3m2!1sen!2sin!4v1717524008086!5m2!1sen!2sin"
                            allowFullScreen="" loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default Contact