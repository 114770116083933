import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import InnerBanner from '../../Components/InnerBanner/InnerBanner';
import eventData from '../../../data/ourCouncil.json'

const PartnerDetails = () => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const path = 'https://pythiangames.org';

    useEffect(() => {
        Object.keys(eventData).map((key) => {
            eventData[key].map((item) => {
                if (item.id == id) {
                    setData(item);
                }
            })
        })
        console.log('Data', data);
    }, [id])

    return (
        <>
            <InnerBanner pageName={"Partner Information"} />
            <section className="cultural section">
                <div className="container">
                    <div className="row g-4 mb-4">
                        <div className="col-md-4 col-lg-3 col-12">
                            <div className="">
                                <img src={`${path}/${data.src}`} className="w-100" alt={data.name || 'Partner Image'} />
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-9 col-12">
                            <div className="">
                                <div className="">
                                    <h2 className="mt-12">{data.name}</h2>
                                    <p><b>{data.profile}</b></p>
                                    {
                                        data.country && <p><b>Country: {data.country}</b></p>
                                    }
                                    <p>{data.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PartnerDetails;
