import React, { useState } from "react";
import InnerBanner from "../../Components/InnerBanner/InnerBanner";
import "./OurCounsil.css";
import { useNavigate } from "react-router-dom";
import data from '../../../data/ourCouncil.json';

const OurCouncil = () => {
    const [isLoading, setIsLoading] = useState(true);

    const Card = ({ id, src, name, title, description }) => {
        const navigate = useNavigate();
        const handleTitleClick = () => {
            navigate(`/our-council/${id}`);
        };
        const imageUrl = `https://pythiangames.org/${src}`;
        return (<div className="col-md-4 col-lg-3 col-12">
            <div className="ambass-card">
                <img src={imageUrl} alt={`${name}'s photo`} />
                <div
                    className="ambass-text"
                    onClick={handleTitleClick}
                    style={{ cursor: "pointer" }}
                >
                    <p className="mt-12 text-center">
                        {name}
                        <span>{title}</span>
                    </p>
                </div>
            </div>
        </div>);
    };

    const renderSection = (sectionData, title) => {
        return (
            <>
                <h2 className="text-center mb-5 mt-5 titleFont title">{title}</h2>
                <div className="row g-4 mb-4">
                    {sectionData.map((item) => (<Card
                        key={item.id}
                        id={item.id} // Pass the id to the Card
                        src={item.src}
                        name={item.name}
                        title={item.profile}
                        description={item.designation}
                    />))}
                </div>
            </>
        );
    };

    return (
        <>
            <InnerBanner pageName="Our Council" />
            <section className="cultural section">
                <div className="container">
                    {renderSection(data.GlobalAmbassadors, "Global Cultural Ambassadors")}
                    {renderSection(data.ExecutiveBoard, "Executive Board")}
                    {renderSection(data.Chairperson, "Chairperson - Committees / Commissions")}
                    {renderSection(data.Advisor, "Advisors")}
                    {renderSection(data.team, "Team Members")}
                </div>
            </section>
        </>
    );
};

export default OurCouncil;